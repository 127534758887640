body {
  margin: 0;
  font-family: "Oxanium";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Oxanium"; /*Can be any text*/
  src: local("Oxanium"),
    url("../fonts/Oxanium-VariableFont_wght.ttf") format("truetype");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.quicksearch {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.vertical-center {
  font-family: "Oxanium";
  min-height: 40%; /* Fallback for browsers do NOT support vh unit */
  min-height: 40vh; /* These two lines are counted as one :-)       */
  display: flex;
  align-items: center;
}

.font-fam {
  font-family: "Oxanium";

}

.dialogicon {
  font-size: 80px !important;
}

.largeicon {
  font-size: 100px !important;
}

.smallsearchbutton {
  max-width: 60px !important;
  min-width: 60px !important;
}


.zoom {
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
}

.pointer {
  cursor: pointer;
}

.zoom:hover {
  transform: scale(1.02);
  -moz-transform: scale(1.02);
  -webkit-transform: scale(1.02);
  -o-transform: scale(1.02);
  -ms-transform: scale(1.02);
}

.allcatholicdark {
  font-family: "Oxanium";
  color: #3c3636;
}

.maxinputwidth {
  max-width: 500px;
}

.maxnotificationwidth {
  max-width: 800px;
}

.gradientdarkbackground {
  background-image: linear-gradient(
    to bottom,
    #cfe2fc,
    #dfecfe,
    #dfecfe,
    #e1eeff,
    #e4efff,
    #e7f1ff
  );
}

.marianblue {
  color: #2a4694;
  font-family: "Oxanium";
}

.marianbutton {
  color: white !important;
  background-color: #2a4694 !important;
}

.allcatholicfont {
  font-family: "Oxanium";
}

.tallerheight {
  line-height: 1.7em;
  font-size: 1em;
}

.adjusttop {
  margin-top: -3px;
}

.squareimage {
  width: 50%;
  height: auto;
  border: 1px solid #d0d0d0;
  object-fit: cover !important; 
  aspect-ratio: 1/1;
  overflow: hidden;
}
.image {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: auto;
  min-height: 100%;
}
.square-image {
  width: 100%; /* Make the container responsive */
  max-width: 100%; /* Limit the width to the image's original width */
  height: 0; /* Create space for the square image */
  padding-top: 100%; /* Set the aspect ratio to create a square */
  overflow: hidden; /* Hide any content that overflows the container */
  position: relative; /* Ensure positioning works as expected */
}

.square-image img {
  object-fit: cover; /* Crop the image while maintaining its aspect ratio */
  position: absolute; /* Position the image within the container */
  top: 0; /* Place the image at the top */
  left: 50%; /* Center the image horizontally */
  transform: translateX(-50%); /* Adjust horizontal position */
}

.square-container {
  border: 1px solid #d0d0d0;
  position: relative;
  width: 100%;
  padding-bottom: 100%; /* This creates a 1:1 aspect ratio (square) */
  overflow: hidden;
}

.square-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.overlaylabel {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 24px; /* Height of the black gradient overlay */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
}

.textlabel {
  position: absolute;
  bottom: 0;
  left: 10px; /* Adjust horizontal position as needed */
  color: white;
  font-size: 10px; /* Adjust font size as needed */
  font-weight: bold;
  line-height: 20px; /* Make the text vertically centered within the overlay */
}

.pill-label {
  position: absolute;
  bottom: 5px;
  left: 5px;
  font-weight: bold;
  background-color: #ffffff; /* Background color of the pill */
  color: black; /* Text color */
  padding: 1px 4px; /* Adjust the padding as needed */
  border-radius: 20px; /* Controls the pill shape */
}

@keyframes pulsate {
  0% {
      transform: scale(1);
      opacity: 0.8;
  }
  50% {
      transform: scale(1.2);
      opacity: 1;
  }
  100% {
      transform: scale(1);
      opacity: 0.8;
  }
}

.pulsecontainer {
  text-align: center;
}

.pulsedot {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: rgb(0, 115, 6);
  border-radius: 50%;
  margin-left: 5px;
  animation: pulsate 1s infinite;
}

@keyframes glow {
  0% {
      box-shadow: 0 0 5px 0 rgba(0, 255, 0, 0.7);
  }
  50% {
      box-shadow: 0 0 20px 10px rgba(0, 255, 0, 0.7);
  }
  100% {
      box-shadow: 0 0 5px 0 rgba(0, 255, 0, 0.7);
  }
}

.glowcontainer {
  text-align: center;
}

.glowdot {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: green;
  border-radius: 50%;
  margin-left: 5px;
  animation: glow 1s infinite;
}

.stickbadge {
  position: absolute;
  top: 6;
  margin: 5px;
  /* right: 50; */
  /* background-color: white; */
  /* color: black; */
  font-size: 12px;
  /* font-weight: bold; */
}
.smallfontsize {
  font-size: 12px !important;
}
.defaultstandardfontsize {
  font-size: 14px !important;
}
.standardfontsize {
  font-size: 16px !important;
}
.responsive {
  width: 100%;
  height: auto;
  display: inline-block;
}

.gradientredbackground {
  background-image: linear-gradient(
    to bottom,
    #faacac,
    #fedfdf,
    #fee2df,
    #ffe3e1,
    #ffe4e4,
    #ffebe7
  );
}

.navshadow {
  background-color: #ffffff;
  border-bottom: 1px solid #2a4694;
  box-shadow: 1px 1px 8px #2a4694;
}

.dashedborder {
  border: 1px dashed #a0a0a0 !important;
  border-radius: 8px;
}

.appearslowly {
  animation: fadeIn 0.2s;
}

.forcetruncate {
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.withtextdeco {
  text-decoration: underline !important;
}

.smallpadding {
  padding: 1px !important;
}

.notextdeco {
  text-decoration: none !important;
}

.namecolor:hover {
  text-decoration: underline !important;
}

.namecolor {
  text-decoration: none !important;
}

.lightframeborder {
  border: 1px solid #dfe1e4;
  border-radius: 9px;
}

.lightframedashedborder {
  border: 1px dashed #dfe1e4 !important;
  border-radius: 8px;
}

div.wrapper {
  z-index: 1000 !important;
}

.ovalcornernocolor {
  border-radius: 20px !important;
}

.selectedcategory {
  border: 1px solid !important;
  font-size: 0.9em;
}

.notselectedcategory {
  border: 1px solid #cfe2ff !important;
  font-size: 0.9em;
}

.roundedframeborder {
  border-radius: 8px;
}

.roundsquareborder {
  color: #efefef;
  border: 1px solid #efefef;
  /* border-radius: 50%; */
}
.roundborder {
  margin: auto;
  color: #2a4694;
  border: 1px solid #2a4694;
  border-radius: 50%;
  padding: 9px;
  width: 45px;
  height: 45px;
}

.roundbutton {
  margin: auto;
  color: #2a4694;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  padding: 9px;
  width: 45px;
  height: 45px;
}

.roundbutton:hover {
  margin: auto;
  color: #fff;
  background-color: #2a4694;
  border: 1px solid #2a4694;
  border-radius: 50%;
  padding: 9px;
  width: 45px;
  height: 45px;
}

.roundcornerbutton {
  margin: auto;
  color: #2a4694;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 8px;
  /* width: 45px;
  height: 45px; */
}

.roundcornerbutton:hover {
  margin: auto;
  color: #fff;
  background-color: #2a4694;
  border: 1px solid #2a4694;
  border-radius: 8px;
  padding: 8px;
  /* width: 45px;
  height: 45px; */
}

.roundcornerlightbutton {
  margin: auto;
  color: #707070;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  padding: 8px;
}

.roundcornerlightbutton:hover {
  margin: auto;
  color: #2a4694;
  /* background-color: #606060; */
  border: 1px solid #2a4694;
  border-radius: 8px;
  padding: 8px;
}

.roundicon {
  margin: auto;
  /* color: #2a4694; */
  /* border: 1px solid #e0e0e0; */
  border-radius: 50%;
  /* padding: 9px; */
  /* width: 45px;
  height: 45px; */
}

.blinking-cursor::after {
  content: "|";
  animation: blink 1s step-end infinite;
}
@keyframes blink {
  from,
  to {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.seemore,
.seemore:hover {
  padding-top: 0px;
  padding-bottom: 4px;
  margin-bottom: 10px;
  color: #888;
  /* font-weight: 600; */
  /* float: right; */
}

.ais-SearchBox-input {
  border-radius: 8px !important;
  height: 50px !important;
  display: flex;
  align-items: center !important;
  font-size: 1.3em !important;
  /* 
  padding-top: 5px !important;
  padding-bottom: 5px !important; */
}
.ais-SearchBox-form {
  height: 50px !important;
  display: flex;
  align-items: center !important;
}

.clear-icon {
  margin-top: -3px !important;
}

.clamp3 {
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3 !important; /* number of lines to show */
  line-clamp: 3 !important;
  -webkit-box-orient: vertical;
}

.clamp2 {
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2 !important; /* number of lines to show */
  line-clamp: 2 !important;
  -webkit-box-orient: vertical;
}

.image-carousel {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.image-carousel img {
  cursor: pointer;
  max-width: 200px;
}

.modalCarousel-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.modalCarousel {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.closeCarousel {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
  color: #888;
}

.modalCarousel img {
  max-width: 100%;
  max-height: 70vh;
}

.prevCarousel,
.nextCarousel {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 24px;
  color: #333;
  background: none;
  border: none;
  outline: none;
}

.prevCarousel {
  left: 20px;
}

.nextCarousel {
  right: 20px;
}
